.hw-card:not(:last-child) {
  margin-bottom: 15px;
}

.hw-qty {
  margin-left: 0 !important;
}

.hw-positive {
  color: #28a745;
}

.hw-negative {
  color: #dc3545;
}

.hw-list {
  height: 45rem;
  overflow-y: scroll;
  background: #eaeaea;
  overflow-x: hidden;
  padding: 15px;
}

.highlight {
  background-color: #fcf8e3 !important;
}

.hidden {
  display: none;
}
