@import "~semantic-ui-css/semantic.min.css";
@import "styles/hardware.css";
@import "components/admin/desk/Desk";

.flex-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  width: 100%;
}

.flex-container > .flex-item {
  width: 100%;
  max-width: 500px;
  margin: 5px;
}
