.hw-list {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  width: 100%;
}

.hw-list > .hw-card {
  width: 100%;
  max-width: 500px;
}

.hw-image > img {
  -webkit-user-drag: none;
  -ms-user-drag: none;
  -moz-user-drag: none;
}
